<template>
  <div class="domain-page">
    <div v-if="isLoading">
      <p>Loading domain information...</p>
    </div>
    <div v-else-if="localDomain">
      <div class="domain-container">
        <h1 class="domain-title">{{ localDomain.name }}</h1>
        <p class="domain-description">{{ localDomain.description }}</p>
        <ul class="features-list">
          <li v-for="feature in localDomain.features" :key="feature">{{ feature }}</li>
        </ul>
        <!-- Contact and Buy Now Buttons -->
        <div class="button-container">
          <a
            :href="localDomain.emailLink"
            class="button contact-button"
          >
            Contact the domain owner directly
          </a>
          <a
            :href="'https://' + localDomain.name"
            target="_blank"
            class="button buy-now-button"
          >
            Buy the domain now
          </a>
        </div>
      </div>
    </div>
    <div v-else class="domain-not-found">
      <h1>Domain Not Found</h1>
      <p>The requested domain could not be found in our records.</p>
    </div>
    <footer>
      <p>© 2024 Baj.ai - All Rights Reserved</p>
    </footer>
  </div>
</template>

<script>
let testDomains = [];

export default {
  data() {
    return {
      localDomain: null,
      isLoading: true, // Added loading state
    };
  },
  created() {
    const pathname = location.pathname;
    const lastSegment = pathname.split('/').pop();
    const domainName = lastSegment.replace('.html', '').trim();

    console.log('[DEBUG] Full Pathname:', pathname);
    console.log('[DEBUG] Extracted Domain Name:', domainName);

    fetch('/test_domains.json')
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log('[DEBUG] Fetched testDomains:', data);
        testDomains = data;

        // Match the domain name
        const resolvedDomain = testDomains.find(
          (domain) =>
            domain.name.trim().toLowerCase() === domainName.toLowerCase()
        );

        console.log('[DEBUG] Resolved Domain:', resolvedDomain);
        this.localDomain = resolvedDomain;
        this.isLoading = false; // Turn off loading state
      })
      .catch((error) => {
        console.error('[ERROR] Failed to fetch testDomains.json:', error);
        this.isLoading = false; // Turn off loading even if error occurs
      });
  },
};
</script>

<style scoped>
.domain-page {
  text-align: center;
  font-family: Arial, sans-serif;
  padding: 2em;
  background: linear-gradient(to bottom, #f7fff7, #e6ffe6); /* Subtle green gradient */
  min-height: 100vh; /* Ensure it fills the viewport */
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Ensure footer stays at the bottom */
}

.domain-container {
  background: white;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 2em;
  width: 80%;
  max-width: 600px;
  margin: 0 auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.domain-title {
  font-size: 2.5em;
  font-weight: bold;
  margin-bottom: 1em;
  color: #007bff;
}

.domain-description {
  font-size: 1.2em;
  margin-bottom: 1.5em;
  color: #555;
}

.features-list {
  list-style: none;
  padding: 0;
  margin: 1.5em 0;
}

.features-list li {
  margin: 0.5em 0;
  font-size: 1em;
  color: #333;
}

.button-container {
  display: flex;
  justify-content: center;
  gap: 1em;
  margin-top: 2em;
}

.button {
  display: inline-block;
  padding: 0.75em 1.5em;
  text-decoration: none;
  color: white;
  font-weight: bold;
  text-align: center;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.contact-button {
  background-color: #28a745; /* Green */
}

.contact-button:hover {
  background-color: #218838;
  transform: scale(1.05);
}

.buy-now-button {
  background-color: #007bff; /* Blue */
}

.buy-now-button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.domain-not-found {
  text-align: center;
  font-family: Arial, sans-serif;
  padding: 2em;
  color: #d9534f;
}

.domain-not-found h1 {
  font-size: 2em;
}

.domain-not-found p {
  font-size: 1.2em;
}

footer {
  margin-top: 2em;
  text-align: center;
  font-size: 1em;
  color: #666;
}

.loading {
  text-align: center;
  font-size: 1.5em;
  color: #666;
  margin: 2em 0;
}
</style>
